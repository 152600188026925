import React from "react"
import Layout from "../../layouts/de"
import FormingToolsPageBase from "../../templates/wire"
import Intro from "../../components/intro"
import Link from "../../components/link"
import Cover from "../../components/cover"
import Illustration from "../../components/illustration"
import Features from "../../components/features"

export default () => {
    return (
        <Layout>
            <FormingToolsPageBase locale="de">
                <Intro title="Umformwerkzeuge für höchste Ansprüche">
                    <p>Seit 1963 bieten wir unseren Kunden nicht nur Draht sondern auch die passenden Umformwerkzeuge. Mit Leidenschaft produzieren und bearbeiten wir Präzisionsteile aus Hartmetall und Stahl für die Kalt-, Halbwarm- und Warmumformung.
Liefertreue und höchster Qualitätsanspruch zeichnen uns dabei aus.</p>
                    <Link to="/forming-tools/delivery-program" className="accessory">Zum Lieferprogramm</Link>
                </Intro>
                <Cover eyebrow="Materialzuschnitt" title="Schnitt nach Maß">
                    <p>Bandsägearbeiten für normal zerspanbares Vollmaterial, Rohre und Profile führen wir mit automatischem Nachschub mit einer Schneidgenauigkeit von 0,1 mm pro 100 mm Schnitthöhe durch.</p>

                    <Features items={[
                        "Maximale Schnittfläche 500 mm x 400 mm oder Ø 400 mm",
                        "Länge: von 10 mm bis 900 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Dreherei" title="Bei uns dreht sich alles um unsere Kunden" align="right">
                    <p>Moderne Maschinen, hochwertige Spannzeuge und erstklassige Zerspanungswerkzeuge ermöglichen es uns, Ihren Anforderungen in Stahl und Hartmetall gerecht zu werden.</p>
                    <p>Wir bearbeiten die verschiedensten Hartmetallqualitäten und Stähle bis zu einer Härte von 68 HRC.</p>

                    <Features items={[
                        "Durchmesser: bis Ø 250 mm",
                        "Länge: bis 1.000 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Fräserei" title="Formgebung in höchstmöglicher Perfektion">
                    <p>Durch die Anbindung an ständig aktualisierte CAD/CAM-Software und die Verwendung modernster, optischer Werkzeugvermessung sind wir in der Lage, höchste Oberflächengüten und kleinste Toleranzen zu realisieren. Dies gilt auch für gehärtete Stähle bis zu einer Härte von 68 HRC sowie Hartmetalle – bereits ab Stückzahl eins.</p>

                    <Features items={[
                        "600 x 500 x 500 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Schleiferei" title="Wir geben Werkzeugen den letzten Schliff" align="right">
                    <p>Mit unseren zahlreichen Flach-, Rund- und Innenschleifmaschinen sowie Honmaschinen bearbeiten wir Hartmetall, Werkzeugstähle, Schnellarbeitsstähle und pulvermetallurgische Stähle.</p>

                    <h4>Innenschleifen</h4>
                    <Features items={[
                        "Durchmesser: von Ø 2 mm bis 200 mm",
                        "Länge: von 2 mm bis 250 mm"
                    ]} />

                    <h4>Außenschleifen</h4>
                    <Features items={[
                        "Durchmesser: bis Ø 250 mm",
                        "Länge: bis 500 mm"
                    ]} />

                    <h4>Flachschleifen</h4>
                    <Features items={[
                        "600 x 400 x 300 mm",
                        "Ø 600 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Funkenerodiererei" title="Wenn der Funke überspringt">
                    <p>In Verbindung mit unseren CNC-Fräsmaschinen und genauer Maßüberwachung stellen wir unsere eigenen Senkerodierwerkzeuge her, die wir auf Maschinen mit einem Hartmetallgenerator zum Einsatz bringen. Auf unseren Drahterodiermaschinen schrecken wir auch vor großen Konizitäten nicht zurück. Das bedeutet höchste Präzision bei Geometrien jeglicher Art und Größe.</p>

                    <h4>Senkerodieren</h4>
                    <Features items={[
                        "730 x 630 x 300 mm"
                    ]} />

                    <h4>Drahterodieren</h4>
                    <Features items={[
                        "550 x 350 x 380 mm"
                    ]} />
                </Cover>
                <Cover eyebrow="Poliererei" title="Wir wissen, worauf es ankommt" align="right">
                    <p>Der wichtigste Schritt für ein haltbares Werkzeug wird bei uns in einer eigenen Abteilung von ausgebildeten Spezialisten durchgeführt. Die letzten µ bis auf das Fertigmaß mit einem Ra von bis zu 0,05 µm garantieren beste Standzeiten.</p>
                </Cover>
                <Cover eyebrow="Qualität" title="Prüfung von Anfang bis Ende - und zwischendurch">
                    <p>In der klimatisierten Qualitätsprüfung suchen wir das µ mit modernster Messtechnik. Selbst tiefste Konturen in kleinen Bohrungen können taktil gemessen werden.
                    Unter Einsatz von Messprojektoren und regelmäßig kalibrierten Handmessmitteln sind wir für alle Ansprüche gerüstet.</p>
                    <p>Bei Bedarf greifen wir für weitere Untersuchungen auch auf die umfangreichen Prüfeinrichtungen des Drahtwerks zurück.</p>
                </Cover>

                <section className="section">
                    <header className="container">
                        <div className="col md:ml-auto md:mr-0 md:w-1/2 lg:w-1/3">
                            <h3 className="section-heading">Der Produktionsprozess im Überblick</h3>
                        </div>
                    </header>
                    <Illustration imageName="dreherei">
                        <h2 className="text-black">Dreherei</h2>
                        <p>Der Rohling wird auf einer CNC-Drehmaschine gefertigt.</p>
                    </Illustration>
                    <Illustration imageName="fraeserei">
                        <h2 className="text-black">Fräserei</h2>
                        <p>Der Rohling bekommt Spannflächen und notwendige Bohrungen.</p>
                    </Illustration>
                    <Illustration imageName="schleiferei">
                        <h2 className="text-black">Schleiferei</h2>
                        <p>Der gehärtete Rohling wird auf Fertigmaß geschliffen.</p>
                    </Illustration>
                    <Illustration imageName="zusammenbau">
                        <h2 className="text-black">Zusammenbau</h2>
                        <p>Der Kern wird in den Rohling eingepresst.</p>
                    </Illustration>
                    <Illustration imageName="poliererei">
                        <h2 className="text-black">Poliererei</h2>
                        <p>Die Innenkontur wird poliert und das Umformwerkzeug ist fertiggestellt.</p>
                    </Illustration>
                </section>
            </FormingToolsPageBase>
        </Layout>
    )
}
